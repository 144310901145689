
import { Vue, Component, Ref } from "vue-property-decorator";
import { ObjectDataSource } from "@/data/Object/ObjecDatatSource";
import {ListDataSource} from "@/data/List/ListDataSource";
import BookletConfigurator from "@/pages/manage/events/components/booklet-configuration-editor/booklet-configurator.vue";
import Html2pdf from "@/components/html2pdf.vue"
import PdfContent from "@/pages/manage/events/components/pdf-content.vue";
const html2pdf =  require('html2pdf.js');
@Component({
  components: {PdfContent, BookletConfigurator, Html2pdf}
})
export default class Home extends Vue {
  @Ref("html2Pdf") html2Pdf:any
  @Ref("content") content:any
  @Ref("contentComponent") contentComponent:any
  loaded:boolean = false;
  dialog:boolean = false;
  htmlContent:string = ""
  dataSource: ObjectDataSource = new ObjectDataSource({
    id: +this.$route.params.bookletConfigurationId,
    config: {},
    className: "BookletConfiguration",
  });

  eventObjectDataSource = new ObjectDataSource({
    id: +this.$route.params.eventId,
    config: {},
    className: "event",
  });

  bookletTemplateDataSource:ListDataSource = new ListDataSource({
    className:"BookletTemplate",
    config:{},
  })

  bookletTemplateObjectDataSource:ObjectDataSource = new ObjectDataSource({
    className:"BookletTemplate",
    config:{},
    id:0
  })

  async downloadPdf() {
    const html = this.bookletTemplateObjectDataSource.model
      .pages
      .map((p:any) => p.blocks
        .map((b:any) => b.htmlView)
        .join(" ")
      )
      .flat()
      .map((b:string) => ` <section class="pdf-item"> ${b} </section> `)
      .join(" <div class=\"html2pdf__page-break\"></div> ")
    var opt = {
      margin: 0,
      filename: 'time_sheet_report.pdf',
      image: { type: 'jpeg', quality: 0.20 },
      html2canvas: { scale: 2,useCORS: true },
      jsPDF: { unit: 'in', format: 'a4', orientation: 'p' }
    };
    this.htmlContent = this.bookletTemplateObjectDataSource.model.htmlView.replace("{body}", html);

    this.$nextTick(()=>{
      setTimeout(()=>{
        html2pdf().set(opt).from(this.content).save();
      }, 1000)
    });




  }

  async updateField(event:any){
    await this.dataSource.updateField({
      fieldName:"bookletTemplateId",
      fieldValue:event
    })
    this.bookletTemplateObjectDataSource.id = event;
    await this.dataSource.get();
    this.bookletTemplateObjectDataSource.model = this.dataSource.model.bookletTemplate;
  }

  get htmlToPdfOptions() {
    return {
      margin: 0,

      filename: "document.pdf",

      image: {
        type: "jpeg",
        quality: 0.98,
      },

      enableLinks: true,

      html2canvas: {
        scale: 1,
        useCORS: true,
      },

      jsPDF: {
        unit: "in",
        format: "a4",
        orientation: "portrait",
      },
    };
  }

  get caption(){
    return this.dataSource.model?.caption
  }

  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
      },
      {
        text: "События",
        to: "/manage/events/",
        exact: true,
      },
      {
        text: this.eventObjectDataSource.model?.caption,
        to: `/manage/events/${this.$route.params.eventId}`,
        exact: true,
      },
      {
        text: "Конфигурации буклетов",
        to: `/manage/events/${this.$route.params.eventId}/zones/booklet-configurations/`,
        exact: true,
      },
      {
        text: this.caption,
        disabled: true,
      },
    ];
  }

  async created() {
    await Promise.all([
      this.eventObjectDataSource.get(),
      this.dataSource.get(),
      this.bookletTemplateDataSource.get()
    ]);

    if(this.dataSource.model.bookletTemplateId){
      this.bookletTemplateObjectDataSource.id = this.dataSource.model.bookletTemplateId;
      this.bookletTemplateObjectDataSource.model = this.dataSource.model.bookletTemplate;
    }
    this.loaded = true;
  }
}
