
import {Vue, Component, Prop} from 'vue-property-decorator';
import {ObjectDataSource} from "@/data/Object/ObjecDatatSource";
import Ace from "@/components/helpers/ace.vue";
import {ListDataSource} from "@/data/List/ListDataSource";
import bloks from "@/pages/manage/booklet-templates/components/bloks.json";
@Component({components: {Ace}})
export default class configurationEditor extends Vue {
  @Prop() bookletTemplateBlock!:any;
  @Prop() bookletConfigurationDataSource!:ObjectDataSource;

  configString:string = "";
  hasConfiguration:boolean = false;
  dataSource:ObjectDataSource = new ObjectDataSource({
    className:"BookletBlockConfiguration",
    config:{},
    id:0
  })

  listDataSource:ListDataSource = new ListDataSource({
    className:"BookletBlockConfiguration",
    config:{}
  })
  $message: any;

  get isSaved(){
    if(!this.dataSource.model?.config) return true;
    if(!this.validateJson(this.configString)) return false;

    return JSON.stringify(this.dataSource.model.config) == JSON.stringify(JSON.parse(this.configString));
  }

  get blockCaption(){
    return  bloks.find(b => b.name == this.bookletTemplateBlock.name)?.caption
  }

  async update(){
    if(!this.validateJson(this.configString)){
      this.$message("Json не правельный","error")
      return;
    }

    this.dataSource.model.config = JSON.parse(this.configString);

    await this.dataSource.updateField({
      fieldName:"config",
      fieldValue:this.dataSource.model.config
    })

    this.$message("Данный успешно сохранены")
  }

  validateJson = (str:string) => {
    try {
      JSON.parse(str);
      return true;
    }catch (e) {
      return false;
    }
  }

  updateConfigString(){
    this.configString = JSON.stringify(this.dataSource.model.config, null, 4)
  }

  async add(){
    const data = await this.listDataSource.add({
      caption:"Конфигурация блока",
      eventId:+this.$route.params.eventId,
      bookletConfigurationId: this.bookletConfigurationDataSource.id,
      bookletTemplateBlockId: this.bookletTemplateBlock.id,
      instanceStateId:4
    })
    this.bookletConfigurationDataSource.model.blocks.push(data);
    this.hasConfiguration = true;
    this.dataSource.model = data;
    this.dataSource.id = data.id;
    this.updateConfigString();
  }

  created(){
    const configuration = this.bookletConfigurationDataSource.model.blocks
      .find((i:any) => i.bookletTemplateBlockId == this.bookletTemplateBlock.id);

    if(!configuration) return;
    this.hasConfiguration = true;
    this.dataSource.model = configuration;
    this.dataSource.id = configuration.id;
    this.updateConfigString();
  }
}
